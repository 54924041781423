.vehicleDetails_2 .VehicleInformation {
    padding: 0.625rem 0rem;
    margin-top: 0.3125rem;
}

.vehicleDetails_2_head {
    background: #F1F1F1;
    padding: 0.3125rem 0rem;
    margin-top: 0.3125rem;
}

.vehicleDetails_2 .vehicleDetails_2_head p {
    font-family: 'Circular Std';
    font-style: normal;
    font-weight: 500;
    font-size: 1rem;
    line-height: 1.25rem;
    display: flex;
    align-items: center;
    text-align: center;
    color: #263238;
}

.vehicleDetails_2 {
    margin: 0px;
}

.vehicleDetails_2_head p {
    margin-left: 0.9375rem !important;
}

.vehicleDetails_2 p {
    gap: 10;
    margin: 0rem;
    margin: 0.625rem 0.25rem;
    font-weight: 500;
    font-size: 1rem;
    line-height: 1.25rem;
    display: flex;
    align-items: center;
    text-align: center;
    color: #263238;
}

.vehicle_images {
    display: flex;
    height: 3.6875em;
    padding: 1.25rem 0rem;
    align-items: center;
    gap: 0.5rem;
    background: #F5FFF1;
}

.vehicleList_wrapper {
    overflow-x: scroll;
    display: flex;
    height: 3.6875em;
    padding: 1.25rem 0rem;
    align-items: center;
    margin: 0rem 15px;
    gap: 0.5rem;
    width: 18.5em;
}

.vehicleList_wrapper_inner {
    width: 5.955em;
    height: 3.6875em;
    display: flex;
    margin-right: 2rem;
}

.vehicleList_wrapper_inner_image {
    width: 100%;
    height: 100%;
    display: flex;
}

.vehicleList_wrapper_inner_image img {
    width: 100%;
    height: 100%;
    display: block;
    object-fit: contain;
}

.add_vehicles {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 25%;
}

.add_vehicles a {
    text-decoration: none;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.add_vehicles p {
    color: #008069;
    text-decoration: none;
    text-align: center;
    font-family: Circular Std;
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    text-decoration: none;
}