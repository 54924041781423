@media only screen and (max-width: 920px) {
    .login {
        display: flex;
        flex-direction: column;
    }

    .login__hero {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin: auto;
        margin-top: 90px;
    }

    .form {
        margin: 0px 24px;
    }

    .header p {
        margin-top: 13px;
        font-weight: 700;
        font-size: 24px;
    }

    .forgotPassword {
        width: 100%;
        display: flex;
        align-items: flex-end;
        justify-content: flex-end;
    }

    .forgotPassword span a {
        text-decoration: none;
        color: #088457;
        display: flex;
        font-weight: 400;
        cursor: pointer;
        margin: 0px;
        margin-bottom: 12px;
        font-size: 14px;
    }

    .login__options p {
        color: #3C3E3F;
        display: flex;
        cursor: pointer;
        font-size: 12px;
        margin: 10px 0px;
        font-weight: 400;
        justify-content: flex-end;
    }

    .login__options span {
        text-decoration: none;
        font-weight: 700;
        color: #088457;
        margin: 0px;
        margin-left: 10px;
    }

    .timer {
        display: flex;
        font-weight: 500;
        font-size: 18px;
        color: #4A4A4A;
        justify-content: flex-end;
    }

    .form__inner {
        display: flex;
        flex-direction: column;
        padding: 15px 0px;
        width: 100%;
        justify-content: space-between;
    }

    .form__inner input {
        width: 100%;
        font-size: 16px;
        border: 1px solid #BABABA;
        border-radius: 6px;
        padding: 8px;
    }

    .form__inner input:focus {
        outline: none;
        border: 1px solid #0080694D;
        background-color: #FBFFFE;
    }

    .form__inner label {
        color: #3C3E3F;
        display: flex;
        width: 100%;
        font-size: 14px;
        font-weight: 400;
        margin-bottom: 0.1rem;
    }

}