.buyDongle {
    display: flex;
    flex-direction: column;
    background-color: #FAFAFA;
}

.vehicleDetails_2_head {
    padding: 5px 0px;
    margin-top: 50px;
    display: flex;
}

.buyDongle__body {
    display: flex;
    flex-direction: column;
    margin-top: 50px;
    margin-bottom: 50px;
}

.buyDongle__bodyInner {
    background-color: #FFFFFF;
    /* margin-top: 12px; */
    margin: 12px 7px;
}

.carType {
    display: flex;
    padding: 5px 10px;
}

.buyDongle__wrapper {
    display: flex;
    width: 100%;
}

.buyDongle__wrapper img {
    width: 100%;
    margin: auto;
    height: 100%;
}

.contract_card {
    border-radius: 0.75rem;
    border: 0.0625rem solid var(--l-green-100, #24B7A4);
    background: var(--color-white, #FFF);
    display: flex;
    width: 100%;
    padding: 2.5rem 1rem;
    flex-direction: column;
    align-items: center;
    gap: 1.5625rem;
}

.contract_card h1 {
    color: var(--color-dark-grey-100, #263238);
    text-align: center;
    font-family: Circular Std;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

.contract_card p {
    color: var(--color-dark-grey-100, #263238);
    text-align: center;
    font-family: Circular Std;
    font-size: 14px;
    font-style: normal;
    font-weight: 450;
    line-height: 24px;
}

.contract_card p span {
    color: var(--color-dark-grey-100, #008069);
}

.contract_card a {
    width: 100%;
    text-decoration: none;
}

.contract_card div {
    width: 100%;
}