.body {
    display: flex;
    flex-direction: column;
}

.heading {
    display: flex;
    height: 4.25em;
    padding: 0.75rem 0rem;
    justify-content: space-between;
    align-items: flex-end;
    background: #D7FBDB;
}

.heading p {
    color: var(--dark, #263238);
    text-align: center;
    margin: auto;
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: Circular Std;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

.all_vehicles {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    margin: 1.25rem 1.875rem;
}

.vehicle_card {
    flex-basis: calc(48.33% - 0.9375rem);
    display: flex;
    width: 7.5em;
    height: 9.5em;
    padding-bottom: 0px;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 0.375rem;
    flex-shrink: 0;
    background-color: #D7FBDB;
    margin: 1.25rem 0rem;
    border-radius: 0.5625rem;
    border: 0.75px solid var(--divider-light-1-px-60, rgba(228, 228, 228, 0.60));
}

.vehicle_card .vehicle_text {
    display: flex;
    background: var(--colors-neutral-white, #FFF);
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    width: 100%;
    height: 100%;
}

.vehicle_card h3 {
    color: var(--color-dark-grey-100, #263238);
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: Circular Std;
    font-size: 0.625rem;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin: 0.1875rem 1.025rem;
}

.vehicle_card h4 {
    color: var(--color-dark-grey-100, #263238);
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: Circular Std;
    font-size: 0.625rem;
    font-style: normal;
    font-weight: 450;
    line-height: normal;
    margin: 0.1875rem 1.025rem;
}

.vehicle_card p {
    color: var(--grey, #999);
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: Roboto;
    font-size: 0.625rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin: 0rem 1.025rem;
}

.vehicleList_wrapper_inner {
    display: flex;
    width: 100%;
    height: 5.375em;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 3.25rem;
    background-color: #D7FBDB;
    margin: 0rem;
}

.vehicleList_wrapper_inner_image {
    display: flex;
    width: 80%;
    height: auto !important;
}

.vehicleList_wrapper_inner_image img {
    width: 100%;
    height: auto;
    display: flex;
}

.body a {
    color: #008069;
    margin: 0rem 1.875rem;
    font-family: Circular Std;
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    text-decoration: none;
}