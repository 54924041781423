.card {
    display: flex;
    width: 37.5em;
    height: 17.75em;
    padding: 2.5rem;
    flex-direction: column;
    align-items: center;
    gap: 1.875rem;
    border-radius: 0.75rem;
    background: var(--color-white, #FFF);
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
}

.card_header h1 {
    color: var(--color-dark-grey-100, #263238);
    text-align: center;
    font-family: Circular Std;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

.card_header p {
    color: var(--color-dark-grey-100, #263238);
    text-align: center;
    font-family: Circular Std;
    font-size: 16px;
    font-style: normal;
    font-weight: 450;
    line-height: normal;
}

.form {
    width: 100%;
    display: flex;
}

.form form {
    width: 100%;
}

.inputs {
    display: flex;
    justify-content: space-between;
    margin-bottom: 1.5rem;
}



.inputs button {
    gap: 0.75rem;
    border: none;
    height: 2.75em;
    width: 45%;
    color: var(--color-white, #FFF);
    text-align: center;
    font-family: Circular Std;
    font-size: 1rem;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    border-radius: 0.25rem;
    cursor: pointer;
    background: #008069;
}

.inputs button:disabled {
    cursor: no-drop;
    border-radius: 4px;
    opacity: 0.4000000059604645;
    background: #008069;
}

.success {
    display: flex;
    align-items: center;
    flex-direction: column;
}

.success svg {
    margin-bottom: 2.25rem;
}

/* @media only screen and (max-width: 540px) { */
@media only screen and (max-width: 920px) {
    .card {
        width: 65%;
        margin: auto;
        height: auto;
    }

    .card_header h1 {
        font-size: 16px;
    }

    .card_header p {
        color: var(--color-grey-green, #8EA396);
        text-align: center;
        font-family: Circular Std;
        font-size: 14px;
        font-style: normal;
        font-weight: 450;
        line-height: normal;
    }

    .inputs {
        flex-direction: column;
        margin-bottom: 0px;
    }

    .inputs button {
        font-size: 0.875rem;
        width: 100%;
    }

    .success h1 {
        font-size: 1.125rem;
    }

    .success p {
        text-align: center;
        font-size: 0.875rem;
    }
}