.vehicleDetails_2 .VehicleInformation {
    padding: 10px 0px;
    margin-top: 5px;
}

.vehicleDetails_2_head {
    background: #F1F1F1;
    padding: 5px 0px;
    margin-top: 5px;
}

.vehicleDetails_2 .vehicleDetails_2_head p {
    font-family: 'Circular Std';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    display: flex;
    align-items: center;
    text-align: center;
    color: #263238;
}

.vehicleDetails_2 {
    margin: 0px;
}

.vehicleDetails_2_head p {
    margin-left: 15px !important;
}

.vehicleDetails_2 .VehicleInformation:nth-child(1) {
    background: #F7F7F7;
}

.vehicleDetails_2 .VehicleInformation:nth-child(1) p {
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    margin: 10px 0px;
    display: flex;
    align-items: center;
    text-align: center;
    color: #263238;
}

.vehicleDetails_2 p {
    gap: 10;
    margin: 0px;
    margin: 10px 4px;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    display: flex;
    align-items: center;
    text-align: center;
    color: #263238;
}

.VehicleInformation {
    display: flex;
    margin: 0px 15px;
}

.VehicleInformation div {
    margin-right: 20px;
}

.VehicleInformation div:nth-child(2) p:nth-child(1),
.VehicleInformation div:nth-child(2) p:nth-child(2) {
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
    display: flex;
    align-items: center;
    text-align: center;
    color: #3C3E3F;

}

.VehicleInformation div:nth-child(2) {
    flex: 1;
    width: 50%;
}

.card_outter {
    display: flex;
    width: 100%;
}

.card {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 13px 0px;
    gap: 28px;
    background: #FFFFFF;
    border: 1px solid var(--l-green-100, #24B7A4);
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.12), 0px 16px 16px rgba(50, 50, 71, 0.08);
    border-radius: 10px;
    order: 1;
    margin: 15px 20px;
    width: 100%;
    flex-grow: 0;
}

.car_details {
    display: flex;
    align-items: center;
    width: 100%;
}

.car_details_inner {
    margin: 13px 16px;
    width: 100%;
}

.car_details_info {
    display: flex;
    margin-bottom: 12px;
    justify-content: space-between;
}

.car_details_row {
    flex: 1;
    max-width: 50%;
    display: flex;
    align-items: center;
}

.car_details_row p,
.car_details_row h3 {
    margin: 0px;
}

.car_details_row svg {
    margin-right: 8px;
}

.car_details_row p {
    font-family: 'Circular Std';
    font-style: normal;
    font-weight: 450;
    font-size: 10px;
    line-height: 15px;
    color: #8EA396;
}

.car_details_row h3 {
    font-family: 'Circular Std';
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    line-height: 18px;
}

.car_details p {
    margin: 0px;
}

.card_personal_info_inner {
    margin: 13px 16px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 0px;
}

.image_outer {
    display: flex;
    align-items: center;
}

.card_personal_info {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
}

.user_info h4 {
    font-family: 'Circular Std';
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 18px;
    display: flex;
    margin: 0px;
    align-items: center;
    color: #263238;
}

.user_info p {
    font-family: 'Circular Std';
    font-style: normal;
    font-weight: 450;
    font-size: 11px;
    line-height: 14px;
    margin: 0px;
    /* identical to box height */

    display: flex;
    align-items: center;

    /* Color/grey green */

    color: #8EA396;
}

.image {
    display: flex;
    width: 3.125em;
    height: 3.125em;
    border-radius: 12px;
    margin-right: 12px;
}

.image .image_wrapper {
    display: flex;
    width: 100%;
    height: auto;
}

.image .image_wrapper img {
    width: 100%;
    height: auto;
    display: flex;
}

.papers_card {
    margin-top: 1.25rem;
    /* width: 21.5625em; */
    width: 100%;
    height: 5.625em;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    border-radius: 0.25rem;
    position: relative;
}

.papers_card_upper {
    display: flex;
    justify-content: space-between;
    height: 2.5em;
    width: 100%;
    /* padding: 0.25rem 0.75rem 0rem 0.75rem; */
    align-items: center;
    gap: 8px;
    border-radius: 0.25rem 0.25rem 0rem 0rem;
    background: linear-gradient(0deg, rgba(255, 255, 255, 0.90) 0%, rgba(255, 255, 255, 0.90) 100%), #FFDFD7;
}

.papers_card_upper div p {
    color: var(--color-dark-grey-100, #263238);
    font-family: Circular Std;
    font-size: 1.125rem;
    font-style: normal;
    font-weight: 450;
    line-height: normal;
}

.papers_card_upper div:nth-child(2) p {
    color: var(--color-red-100, #FD4D1E);
    font-family: Circular Std;
    font-size: 1rem;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}


.papers_card_lower {
    display: flex;
    width: 100%;
    height: 3.125em;
    /* padding: 0rem 0.75rem; */
    align-items: center;
    justify-content: space-between;
    gap: 0.5rem;
    border-radius: 0rem 0rem 0.25rem 0.25rem;
    background: var(--color-light-red-100, #FFDFD7);
}

.papers_card_lower div {
    display: flex;
    align-items: center;
}

.papers_card_lower div svg {
    margin-right: 0.5rem;
}

.papers_card_lower div p {
    color: var(--color-dark-grey-100, #263238);
    font-family: Circular Std;
    font-size: 1rem;
    font-style: normal;
    font-weight: 500;
    margin: 0px;
    line-height: normal;
}

.papers_card_lower div:nth-child(2) {
    flex-direction: column;
    display: flex;
    align-items: flex-end;
}

.papers_card_lower div:nth-child(2) p {
    color: var(--color-dark-grey-100, #263238);
    font-family: Circular Std;
    font-size: 0.875rem;
    margin: 0px;
    font-style: normal;
    font-weight: 450;
    line-height: normal;
}

.papers_card_lower div:nth-child(2) p:nth-child(2) {
    color: var(--color-dark-grey-100, #263238);
    font-family: Circular Std;
    font-size: 0.625rem;
    font-style: normal;
    font-weight: 450;
    line-height: normal;
}


@media only screen and (max-width: 320px) {
    .VehicleInformation {
        margin: 0px 0px;
    }
}